import PropTypes from 'prop-types'
import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from './layout'
import SEO from './seo'

import MyAccordion from './my_accordion'
import MyCard from './my_card'
import MyCardDeck from './my_card_deck'

const MyPage = ({
  pageTitle,
  pageSeoTitle,
  pageType,
  pageContent,
  pageCards
}) => {
  return (
    <Layout>
      <SEO title={pageSeoTitle || pageTitle} />
      <section className="page-section">
        <Container fluid>
          <Row>
            <Col className="my-auto">
              <h2>{pageTitle}</h2>
              {
                {
                  accordion: <MyAccordion cards={pageCards} />,
                  card: <MyCard cards={pageCards} />,
                  cardDeck: <MyCardDeck cards={pageCards} />,
                  simple: <span>{pageContent}</span>
                }[pageType]
              }
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

MyPage.propTypes = {
  pageTitle: PropTypes.string,
  pageSeoTitle: PropTypes.string,
  pageType: PropTypes.string,
  pageContent: PropTypes.element,
  pageCards: PropTypes.arrayOf(PropTypes.object)
}

MyPage.defaultProps = {
  pageTitle: '',
  pageType: 'accordion',
  pageContent: <span></span>,
  pageCards: []
}

export default MyPage
