import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import logo from '../../static/images/logo.png'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

const Header = ({ siteTitle }) => (
  <header>
    <Container>
      <Navbar collapseOnSelect expand="lg" variant="light" id="sideNav">
        <Navbar.Brand href="/">
          <img
            src={logo}
            className="d-inline-block align-top img-fluid img-brand"
            alt="Mūsų Pėdutės"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav justify-content-end">
          <Nav className="ml-auto" defaultActiveKey="/">
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/apie_mus"
            >
              <img src="/images/nav/apie_mus.png" aria-hidden="true"></img>
              apie mus
            </Link>
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/ugdymas"
            >
              <img src="/images/nav/ugdymas.png" aria-hidden="true"></img>
              ugdymas
            </Link>
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/specialistai"
            >
              <img src="/images/nav/specialistai.png" aria-hidden="true"></img>
              specialistai
            </Link>
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/praktine_informacija"
            >
              <img
                src="/images/nav/praktine_informacija.png"
                aria-hidden="true"
              ></img>
              praktinė informacija
            </Link>
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/galerija"
            >
              <img src="/images/nav/galerija.png" aria-hidden="true"></img>
              galerija
            </Link>
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/tevu_atsiliepimai"
            >
              <img
                src="/images/nav/apie_mus_kalba.png"
                aria-hidden="true"
              ></img>
              tėvų atsiliepimai
            </Link>
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/kaina"
            >
              <img src="/images/nav/kaina.png" aria-hidden="true"></img>
              kaina
            </Link>
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/kontaktai"
            >
              <img src="/images/nav/kontaktai.png" aria-hidden="true"></img>
              kontaktai
            </Link>
            <Link
              className="nav-link nav-link-img"
              activeClassName="nav-link-img-active"
              to="/draugai"
            >
              <img src="/images/nav/draugai.png" aria-hidden="true"></img>
              draugai
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: `Pradžia`
}

export default Header
