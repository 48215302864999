import React from 'react'

const BackgroundVideo = () => {
  return (
    <div className="background-wrapper">
      <video
        autoplay
        loop
        muted
        playsinline
        className="masterbackground"
        poster="https://thumbs.gfycat.com/WeakShortCoot-poster.jpg"
      >
        <source
          id="webmsource"
          src="https://zippy.gfycat.com/WeakShortCoot.webm"
          type="video/webm"
        />
        <source
          id="mp4source"
          src="https://zippy.gfycat.com/WeakShortCoot.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  )
}

export default BackgroundVideo
