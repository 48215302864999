import PropTypes from 'prop-types'
import React from 'react'

import { BsChevronRight } from 'react-icons/bs'

import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

const MyAccordion = ({ cards }) => {
  return (
    <Accordion>
      {cards.map((card, index) => (
        <Card key={index}>
          <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
            <BsChevronRight />
            &nbsp;
            {card.title}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={index + 1}>
            <Card.Body>{card.body}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}

MyAccordion.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object)
}

MyAccordion.defaultProps = {
  cards: []
}

export default MyAccordion
