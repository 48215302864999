import PropTypes from 'prop-types'
import React from 'react'

import CardColumns from 'react-bootstrap/CardColumns'
import Card from 'react-bootstrap/Card'

const MyCardDeck = ({ cards }) => {
  return (
    <CardColumns>
      {cards.map((card, index) => (
        <Card key={index} bg={card.hasOwnProperty('bg') ? card.bg : 'light'}>
          {card.img ? (
            <Card.Img variant="top" src={card.img} className="img-thumbnail" />
          ) : (
            ''
          )}
          <Card.Body>
            <Card.Title>{card.title}</Card.Title>
            {card.preBody ? card.preBody : ''}
            <Card.Text>{card.body}</Card.Text>
          </Card.Body>
        </Card>
      ))}
    </CardColumns>
  )
}

MyCardDeck.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object)
}

MyCardDeck.defaultProps = {
  cards: []
}

export default MyCardDeck
