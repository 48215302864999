import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import CookieConsent, { Cookies } from 'react-cookie-consent'

const Footer = () => (
  <footer>
    <Container fluid>
      <Row>
        <CookieConsent
          // debug={true}
          location="bottom"
          buttonText="Sutinku"
          declineButtonText="Nesutinku"
          cookieName="musupedutes-gdpr-facebook-pixel"
        >
          Šioje svetainėje naudojame slapukus (angl. „cookies“). Jie padeda mums
          keisti svetainę, kad ji būtų Jums patogesnė.
        </CookieConsent>
      </Row>
      <Row>
        <Col className="my-auto footer-copyright text-center py-3 font-small">
          &#169; 2016 Visos teisės saugomos:
          <a href="http://www.musupedutes.lt"> www.musupedutes.lt</a>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
