import PropTypes from 'prop-types'
import React from 'react'

import Card from 'react-bootstrap/Card'

const MyCard = ({ cards }) => {
  return (
    <span>
      {cards.map((card, index) => (
        <Card key={index}>
          <Card.Header>{card.title}</Card.Header>
          <Card.Body>{card.body}</Card.Body>
        </Card>
      ))}
    </span>
  )
}

MyCard.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object)
}

MyCard.defaultProps = {
  cards: []
}

export default MyCard
