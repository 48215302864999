/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, title }) {
  const { pathname } = useLocation()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  )
  const schemaJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'Preschool',
    name:
      'MŪSŲ PĖDUTĖS - naujas privatus darželis Vilniuje Antežeriuose, šalia Pašilaičių ir Perkūnkiemio',
    url: 'http://www.musupedutes.lt',
    sameAs: [
      'https://www.facebook.com/musu.pedutes',
      'https://www.instagram.com/musu.pedutes'
    ],
    logo: 'http://www.musupedutes.lt/images/logo.png',
    telephone: ['Agnė +370 671 72480', 'Evelina +370 674 72530'],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Ežero g. 46',
      addressLocality: 'Antežeriai',
      addressRegion: 'Vilniaus raj. sav.',
      postalCode: '14158',
      addressCountry: 'LT'
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 54.74045,
      longitude: 25.17363
    },
    areaServed: 'Vilnius',
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '07:30',
        closes: '18:00'
      }
    ]
  }

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${pathname}`
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.image}`
        }
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(schemaJSONLD)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `lt`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
